<template>
  <div class="window-faq d-flex justify-content-center align-items-center mx-auto m-5">
    <vue-collapsible-panel-group v-if="lang === 'ar'" dir="rtl" accordion>
        <vue-collapsible-panel v-for="question in questions" :key="question.id" :expanded="false">
            <template #title>
              <div class="letfToRightLangTitle">
                <span v-html="question.question"></span>
              </div>
            </template>
            <template #content>
              <div class="letfToRightLangBody">
                <span v-html="question.answer"></span>
              </div>
            </template>
        </vue-collapsible-panel>
    </vue-collapsible-panel-group>
    <vue-collapsible-panel-group v-else accordion>
        <vue-collapsible-panel v-for="question in questions" :key="question.id" :expanded="false">
            <template #title>
              <div>
                <span v-html="question.question"></span>
              </div>
            </template>
            <template #content>
              <div>
                <span v-html="question.answer"></span>
              </div>
            </template>
        </vue-collapsible-panel>
    </vue-collapsible-panel-group>
  </div>
</template>
<script>
import { VueCollapsiblePanelGroup, VueCollapsiblePanel, } from '@dafcoe/vue-collapsible-panel';
export default {
 components: {
     VueCollapsiblePanelGroup,
     VueCollapsiblePanel,
  },
  computed: {
    questions() {
      return this.$store.getters['questions/getQuestions'];
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
  },
};
</script>
<style src="./FAQ.css"></style>